'use strict';
import jsonLogic from 'json-logic-extension';
import {Formio, Utils as DirectFormioUtils} from 'formiojs';
import {jsonLogic as realJsonLogic} from 'formiojs/utils/utils';
// expose custom json logic function in formio
Object.keys(jsonLogic.customOperations).forEach(function (k) {
    realJsonLogic.add_operation(k, jsonLogic.customOperations[k]);
});

var allowedAttrs = [
    'ref',
    'src',
    'url',
    'data-oembed-url',
    'onclick',
    'onchange',
    'style',
    'id',
    'checked',
    'width',
    'height',
    'hidden',
    'stroke-width',
    'fill',
    'r',
    'cx',
    'cy',
    'stroke-linecap',
    'data',
    'aria-required'
];

const allowedTags = [
    "object",
    "iframe"
];

const formOpts = {
    sanitizeConfig: {
        allowedAttrs: allowedAttrs,
        addAttr: allowedAttrs,
        allowedTags: allowedTags,
        addTags: allowedTags
    },
    buttonSettings: {
        showCancel: false,
        showPrevious: false,
        showNext: false,
        showSubmit: false
    },
    i18n: {}
};

function applyCustomJsonLogicToFormioUtils() {
    Object.keys(jsonLogic.customOperations).forEach(function (k) {
        DirectFormioUtils.jsonLogic.add_operation(k, jsonLogic.customOperations[k]);
    });
}

export function createPartAPreviewForm(elementId, schema, options={}) {
    let form = null;
    applyCustomJsonLogicToFormioUtils();
    const currentFormOpts = {...formOpts, ...options}
    currentFormOpts['i18n'][CURRENT_LANGUAGE] = GLOBALS.TRANSLATIONS;
    currentFormOpts['noDefaults'] = true;
    if (GLOBALS.DECIMAL_SEPARATOR) currentFormOpts['decimalSeparator'] = GLOBALS.DECIMAL_SEPARATOR;
    if (GLOBALS.DELIMITER) currentFormOpts['thousandsSeparator'] = GLOBALS.DELIMITER;

    return Formio.createForm(document.getElementById(elementId), schema, currentFormOpts)
        .then(function (f) {
            form = f;
            f.language = CURRENT_LANGUAGE;
        });
}
